import React, {Component } from "react";
import OrdersAll from './components/OrderAll';
import Login from './components/Login';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogged: false  }
  }

  componentDidMount () {
   
    let getToken = sessionStorage.getItem('tokenDashboard');

    if(getToken && getToken.length > 0) {
      this.setState({
        isLogged: true
      })
    }
  }


  render() {

    var {isLogged} = this.state;

    if(!isLogged) {
      return (
        <div style={{display: 'flex', bottom: 0, justifyContent: 'center'}}>
            <Login />
        </div>)
    }
    else {

    return (
      <React.Fragment>
        <OrdersAll/>
    </React.Fragment>
    );

  }

  }
}

export default App;